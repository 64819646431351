<template>
  <div class="w-100">
    <div 
      class="main-container-add-tags" 
      @click.stop="toggleContainer"
      autocomplet="off"
    >
      <div class="d-flex flex-wrap">
        <b-badge class="light-secondary-v2 mcustom-05" variant="light-secondary-v2" v-for="(tag, index) in casting_call.tags" :key="index">
          {{tag}}
          <feather-icon icon="XIcon" @click.stop="deleteTag(tag)"></feather-icon>
        </b-badge>
      </div>
      <feather-icon icon="ChevronDownIcon" class="float-right"></feather-icon>
      <div @click.stop class="container-input-add-tags d-none" ref="container_input_add_tags">
        <div>
          <b-form-input :placeholder="$t('creator.createTag')" v-model="tag" @keyup.enter="casting_call.tags.find((t) => t === tag) !== undefined ? '' : addTag()"></b-form-input>
        </div>
        <b-button @click="addTag" :disabled="tag.length === 0 || casting_call.tags.find((t) => t === tag) !== undefined" variant="success-add-tag" class="success-add-tag"><span class="ml-1">{{$t('creator.addTagOne')}}</span> <span v-if="tag.length > 0">"{{tag}}"</span></b-button>
      </div>
    </div>
  </div>    
</template>

<script>
import {
  BFormInput,
  BButton,
  BBadge,
} from 'bootstrap-vue';

export default {
  name: 'AddTagsComponent',
  components: {
    BFormInput,
    BButton,
    BBadge,
  },
  props: {
    casting_call: {
      required: true,
    }
  },
  data() {
    return {
      tag: '',
    }
  },
  mounted() {
    document.addEventListener('click', this.removeContainer);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.removeContainer);
  },
  methods: {
    removeContainer(event) {
      if (!this.$refs['container_input_add_tags'].contains(event.target)) {
        this.$refs['container_input_add_tags'].classList.add('d-none')
      }
    },
    toggleContainer() {
      this.$refs['container_input_add_tags'].classList.toggle('d-none');
    },
    addTag() {
      this.casting_call.tags.push(this.tag)
      this.tag = '';
    },
    deleteTag(name_tag) {
      this.casting_call.tags = this.casting_call.tags.filter((t) => t !== name_tag);
    },
  }
}
</script>
<style scoped lang="scss">
.main-container-add-tags {
  min-height: 45px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d8d6de;
  border-radius: .35rem;
  padding: .5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  .float-right {
    position: absolute;
    right: 1em;
  }
  .mcustom-05 {
    margin: 0.2em 0.2em 0.2em
  }
  .container-input-add-tags {
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: -7.5em;
    left: 0;
    border: 1px solid #d8d6de;
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
    z-index: 100;
  }

  .light-secondary-v2 {
    background-color: #EFF3F6;
    color: #718096;
    font-weight: 400;
  }
  
  .success-add-tag {
    background-color: #0096fe !important;
    text-align: left !important;
    width: 100%;
    margin: 0.5em 0 0 0;
    color: white ;
    transition: all 300ms;
    // height: 24px;
  }
  .success-add-tag:disabled:hover {
    box-shadow: none !important;
    color: white !important;
  }
}
</style>